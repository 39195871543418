import { Component } from '@angular/core';
import { MenuComponent } from '@comMobileApp/layout/components/menu';
import {
  IonApp,
  IonRouterOutlet
} from '@ionic/angular/standalone';

@Component({
  selector: 'ionic-app-root',
  templateUrl: 'ionicApp.component.html',
  styleUrls: ['ionicApp.component.scss'],
  imports: [
    IonApp,
    IonRouterOutlet,
    MenuComponent
  ],
  standalone: true
})
export class IonicAppComponent {

  constructor(
  ) { }

}
